import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {

    this.resizeMasonryGrid();
    window.addEventListener('resize', this.resizeMasonryGrid);
  }

  resizeMasonryGrid() {
    console.log('testing')
    const container = document.getElementById('building_media_grid');
    const items = container.getElementsByClassName('grid-item');
    let columnCount;

    if (window.innerWidth >= 1200) {
      columnCount = 4; // 4 columns for large screens
    } else if (window.innerWidth >= 768) {
      columnCount = 3; // 3 columns for medium screens
    } else {
      columnCount = 2; // 2 columns for small screens
    }

    container.style.columnCount = columnCount;
  }
}
