import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let self = this;
    $('div.cate-item').on('click', function(){
      self.handleActiveCategory($(this).attr('for'))
    })
  }

  handleActiveCategory(cate_id){
    $('div.cate-media-container > .grid-container').removeClass('active');
    $(`#cate_media_${cate_id}`).addClass('active');
  }
}
